.compound-target-discovery {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #32a852;
  }
  
  nav ul {
    display: flex;
    list-style-type: none;
  }
  
  nav ul li {
    margin-left: 2rem;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .hero {
    background-color: #fff;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    color: #6A69FB;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    background-color: #6A69FB;
    color: #fff;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    padding: 4rem 2rem;
    background-color: #fff;
  }
  
  .feature {
    flex-basis: 30%;
    text-align: center;
  }
  
  .feature h2 {
    color: #6A69FB;
    margin-bottom: 1rem;
  }
  
  .image-section {
    text-align: center;
    padding: 4rem 2rem;
    background-color: #f0f8f0;
  }
  
  .image-section img {
    max-width: 100%;
    height: auto;
  }
  
  .cta-section {
    text-align: center;
    padding: 4rem 2rem;
    background-color: #6A69FB;
    color: #fff;
  }
  
  .cta-section h2 {
    margin-bottom: 2rem;
  }
  
  .cta-section .cta-button {
    background-color: #fff;
    color: #6A69FB;
  }
  
  footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
  }

  .logo-img {
    height: 7.5%;
    width: 7.5%;
  }
  
  .legend h3 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .legend p {
    margin: 0;
    font-size: 14px;
  }
  
  .top-scores h3 {
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .top-scores p {
    margin: 0;
    font-size: 14px;
  }
  