.home-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-section {
  height: 0px;
}

.graph-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-container {
  width: 100%;
  height: 100%;
}

.homepage {
  font-family: 'Times New Roman', Times, serif;
  margin: 0;
  padding: 70px;
  background-color: white;
}

.homepage #maincontent {
  max-width: 650px; /* Adjust width to our needs */
  margin: 0 auto;
  text-align: left; /* Left-align the text */
}

.homepage h2 {
  all: revert;
  margin-top: 0;
}

.homepage p {
  line-height: 1.6;
}

.homepage .quote {
  font-style: italic;
  font-size: 0.9em;
  margin-top: 20px;
  text-align: left; /* Left-align the quote */
}

a {
  color: blue;
  text-decoration: underline;
}