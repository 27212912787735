/* GeneNetwork.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.sliders {
    position: absolute;
    top: 20%;
    right: 30px;
    background: white;
    padding: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: min(20vw, 160px);
}

.special-height {
    min-height: 80%;
}

.legend, .top-scores {
    position: absolute;
    left: 30px;
    background: white;
    padding: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: min(20vw, 160px);
}

.legend {
    top: 20%; /* Align with sliders box */
}

.top-scores {
    top: 40%; /* Space it vertically from the legend box */
}

.top-scores table {
    width: 100%;
    font-weight: normal;
}

.top-scores td {
    padding-right: 5px;
}

.categories {
    position: absolute;
    top: 50vh;
    right: 30px;
    background: white;
    padding: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-weight: bold;
    width: min(20vw, 160px);
}

/* Loader Styles */
.loader {
  border-top-color: #3498db;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
