/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Apply the Roboto font family */
body {
    font-family: 'Roboto', sans-serif;
}

/* Style the search container */
.search-container {
    position: relative;
    z-index: 100; /* Ensure it appears above other elements */
    display: flex;
    align-items: center;
    transform: translateY(5vh);
}

/* Style the search input */
.search-input {
    width: 70%; /* Adjust width as needed */
    padding: 10px;
    border: 2px solid #6A69FB;
    border-radius: 25px;
    font-size: 16px;
    outline: none;
}

/* Style the custom file upload button */
.custom-file-upload {
    margin-left: 10px;
    padding: 10px;
    border: 2px solid #6A69FB;
    border-radius: 50%; 
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #6A69FB;
}

/* Hover effect for the custom file upload button */
.custom-file-upload:hover {
    background-color: #6A69FB;
    color: white;
}

/* Hide the actual file input */
#file-upload {
    display: none;
}
