/* General styles */
body {
  font-family: 'Roboto', sans-serif; /* Modern font, great for a clean and minimalistic look */
  line-height: 1.8; /* Increased for better readability */
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333;
}

/* Container styles */
.api-doc-container {
  padding: 30px; /* Increased padding for a spacious feel */
  background: white;
}

/* Header styles */
.api-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px; /* More space for a cleaner look */
  margin-bottom: 30px; /* Increased margin for better section separation */
}

.api-header h1 {
  font-size: 26px; /* Slightly larger font size for better emphasis */
  margin: 0;
  color: #0073bb;
  font-weight: 500; /* Lighter weight for a modern look */
}

.api-header p {
  margin: 8px 0 0; /* Slightly increased for breathing space */
  font-size: 16px;
  color: #555; /* Softer color for better readability */
}

/* Section styles */
.api-section {
  margin-bottom: 40px; /* Increased spacing between sections for clarity */
}

.api-section h2 {
  font-size: 20px; /* Slightly larger for better hierarchy */
  margin-bottom: 15px; /* More space below header */
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  color: #333; /* Darker color for better contrast */
  font-weight: 500;
}

/* Code block styles */
.code-block {
  background-color: #f5f5f5; /* Softer color for a minimal look */
  padding: 15px; /* More padding for comfortable reading */
  border-radius: 8px; /* Rounded corners for a more modern look */
  font-family: 'Fira Code', monospace; /* Sleek monospace font */
  font-size: 14px;
  overflow-x: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Table styles */
.api-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* More space above table */
  font-size: 15px; /* Slightly increased for readability */
}

.api-table th,
.api-table td {
  border: 1px solid #ddd;
  padding: 12px; /* Increased padding for better spacing */
  text-align: left;
}

.api-table th {
  background-color: #f1f1f1; /* Softer background for headers */
  font-weight: 600; /* Bold to differentiate headers */
}

.api-table td code {
  font-family: 'Fira Code', monospace;
  background-color: #f5f5f5;
  padding: 3px 5px; /* Increased padding for better readability */
  border-radius: 5px; /* Slightly rounded for a sleek look */
}

/* Sidebar styles */
.docs-container {
  display: flex;
}

.sidebar {
  width: 270px; /* Slightly increased width for a more spacious sidebar */
  background-color: #ffffff; /* White background for a clean look */
  border-right: 1px solid #ddd;
  padding: 30px; /* Increased padding */
  position: fixed;
  height: 100%;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.sidebar h3 {
  font-size: 22px; /* Slightly larger */
  color: #0073bb;
  margin-bottom: 20px; /* Increased margin for clarity */
  font-weight: 600; /* Bold for emphasis */
}

.sidebar ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebar ul li {
  margin: 15px 0; /* Increased spacing for clarity */
}

.sidebar button {
  background: none;
  border: none;
  font-size: 16px;
  color: #0073bb;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: 5px 0; /* Added padding for better spacing */
  transition: color 0.3s ease; /* Smooth color transition */
}

.sidebar button:hover {
  text-decoration: underline;
  color: #005a99; /* Darker color for better feedback */
}

.sidebar ul ul {
  margin-top: 8px; /* More spacing for nested items */
  padding-left: 25px; /* Clearer nesting */
}

.sidebar ul ul li a {
  font-size: 15px;
  color: #555; /* Softer color for nested items */
  text-decoration: none;
}

.sidebar ul ul li a:hover {
  text-decoration: underline;
}

/* Main content area */
.docs-content {
  margin-left: 300px; /* Adjusted to match sidebar width */
  padding: 30px; /* Increased padding for more space */
  width: calc(100% - 300px);
}

/* Link button styles */
.link-button {
  background: none;
  border: none;
  color: #0073bb;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  padding: 0;
  transition: color 0.3s ease; /* Smooth hover effect */
}

.link-button:hover {
  text-decoration: none;
  color: #005a99; /* Slightly darker shade to indicate hover */
}
